<template>
  <div class="gigawhat-user_acces_tab">
    <p class="gigawhat-tab_description">
      Choisir une ou plusieurs autorisations:
    </p>
    <div class="accordion" role="tablist">
      <div class="giga-accordion_row">
        <div 
            class="accordion-row_header" 
            header-tag="header" 
            role="tab"
            >
          <b-button 
            class=""
            block
            v-b-toggle.accordion-ouvrages
            >
            <i class="accordion-row_icon">
                <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.24537e-08 5.4C2.30796e-08 5.736 0.2475 6 0.5625 6L8.4375 6C8.7525 6 9 5.736 9 5.4C9 5.244 8.94375 5.112 8.85375 5.004C8.76375 4.896 4.91625 0.204 4.91625 0.204C4.815 0.0839997 4.66875 -3.7865e-07 4.5 -3.93402e-07C4.33125 -4.08155e-07 4.185 0.0839997 4.08375 0.204L0.14625 5.004C0.0562497 5.112 6.60916e-08 5.244 5.24537e-08 5.4Z" fill="#132D48"/>
                </svg>
            </i>
            Ouvrages</b-button
          >
        </div>
        <b-collapse
          class="accordion-tabpanel"
          id="accordion-ouvrages"
          accordion="acces-row"
          role="tabpanel"
        >
          <ul class="accordion-panel_header">
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label v-if="!enableSearch" for="DDD">Tous les ouvrages</label>
              <input v-if="enableSearch" type="search" name="" id="" placeholder="Recherche">

              <!-- SEARCH -->
              <button class="acces-user_search" @click="enableUserSearch()">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.7448 22.6899L17.772 16.7171C21.2724 12.4326 20.6421 6.12211 16.3576 2.62172C12.0768 -0.878671 5.76261 -0.248376 2.26222 4.03613C-1.23817 8.32064 -0.607873 14.6311 3.67663 18.1315C7.36461 21.1479 12.6696 21.1479 16.3576 18.1315L22.3304 24.1043C22.7431 24.472 23.3771 24.4382 23.7448 24.0255C24.0862 23.6466 24.0862 23.0726 23.7448 22.6899ZM10.0321 18.3941C5.60879 18.3941 2.02586 14.8149 2.02211 10.3916C2.02211 5.96829 5.60129 2.38536 10.0246 2.38161C14.4479 2.38161 18.0309 5.96078 18.0346 10.3841C18.0346 10.3841 18.0346 10.3879 18.0346 10.3916C18.0271 14.8074 14.4479 18.3866 10.0321 18.3941Z" fill="#8C9DAB"></path></svg>
              </button>
            </li>
          </ul>
          <ul class="accordion-panel_header">
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°1</label>
            </li>
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°2</label>
            </li>
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°3</label>
            </li>
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°1</label>
            </li>
          </ul>
        </b-collapse>
      </div>
      <div class="giga-accordion_row">
        <div 
            class="accordion-row_header" 
            header-tag="header" 
            role="tab"
            >
          <b-button 
            block 
            v-b-toggle.accordion-simulations
            >
            
            <i class="accordion-row_icon">
                <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.24537e-08 5.4C2.30796e-08 5.736 0.2475 6 0.5625 6L8.4375 6C8.7525 6 9 5.736 9 5.4C9 5.244 8.94375 5.112 8.85375 5.004C8.76375 4.896 4.91625 0.204 4.91625 0.204C4.815 0.0839997 4.66875 -3.7865e-07 4.5 -3.93402e-07C4.33125 -4.08155e-07 4.185 0.0839997 4.08375 0.204L0.14625 5.004C0.0562497 5.112 6.60916e-08 5.244 5.24537e-08 5.4Z" fill="#132D48"/>
                </svg>
            </i>
            Simulations</b-button
          >
        </div>
        <b-collapse
          class="accordion-tabpanel"
          id="accordion-simulations"
          accordion="acces-row"
          role="tabpanel"
        >
          
          <ul class="accordion-panel_header">
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label v-if="!enableSearch" for="">Tous les ouvrages</label>
              <input v-if="enableSearch" type="search" name="" id="" placeholder="Recherche">

              <!-- SEARCH -->
              <button class="acces-user_search" @click="enableUserSearch()">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.7448 22.6899L17.772 16.7171C21.2724 12.4326 20.6421 6.12211 16.3576 2.62172C12.0768 -0.878671 5.76261 -0.248376 2.26222 4.03613C-1.23817 8.32064 -0.607873 14.6311 3.67663 18.1315C7.36461 21.1479 12.6696 21.1479 16.3576 18.1315L22.3304 24.1043C22.7431 24.472 23.3771 24.4382 23.7448 24.0255C24.0862 23.6466 24.0862 23.0726 23.7448 22.6899ZM10.0321 18.3941C5.60879 18.3941 2.02586 14.8149 2.02211 10.3916C2.02211 5.96829 5.60129 2.38536 10.0246 2.38161C14.4479 2.38161 18.0309 5.96078 18.0346 10.3841C18.0346 10.3841 18.0346 10.3879 18.0346 10.3916C18.0271 14.8074 14.4479 18.3866 10.0321 18.3941Z" fill="#8C9DAB"></path></svg>
              </button>
            </li>
          </ul>
          <ul class="accordion-panel_header">
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°1</label>
            </li>
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°2</label>
            </li>
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°3</label>
            </li>
            <li class="accordion-item">
              <div class="giga-custom_checkbox">
                <input type="checkbox" name="" id="DDD" />
                <label></label>
              </div>
              <label for="">Ouvrage n°1</label>
            </li>
          </ul>
        </b-collapse>
      </div>
      <!-- <div>
                    <div header-tag="header" role="tab">
                        <button v-b-toggle.accordion-1 ><span >Ouvrages</span></button>
                    </div>
                    <div id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <ul>
                        <li>
                            <input type="checkbox" name="" id="">
                            <label for="">Tous les ouvrages</label>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <input type="checkbox" name="" id="">
                            <label for="">Ouvrage n°1</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id="">
                            <label for="">Ouvrage n°2</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id="">
                            <label for="">Ouvrage n°3</label>
                        </li>
                        <li>
                            <input type="checkbox" name="" id="">
                            <label for="">Ouvrage n°1</label>
                        </li>
                    </ul>
                    </div>
                </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAcces",
  data(){
      return{
          enableSearch: false
      }
  },
  methods:{
      enableUserSearch(){
          this.enableSearch = !this.enableSearch
      }
  }
};
</script>