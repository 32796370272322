<template>
   <component :$v="$v" :userForm="userForm" :is="component" ></component>
</template>

<script>

import UserInfos from "./NewUser/UserInfos";
import UserRole from "./NewUser/UserRole";
import UserAcces from "./NewUser/UserAcces";

export default {
  name: "UserSteper",
  components: {
    UserInfos,
    UserRole,
    UserAcces
  },
  props: ['currentTab', 'userForm', '$v'],
  computed:{
    component(){
      let mappings = {
        1: 'UserRole',
        2: 'UserInfos',
        3: 'UserAcces'
      }
      return mappings[this.currentTab];
    },
  },
    
}

</script>
